import React, { useState } from "react"
import Seo from "../components/seo"
import {MainContainer, dark, StyledButton, highlight, light} from "../components/styles"
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from 'gatsby-link'
import {
  faUser,
  faAt,
  faPenNib,
  faEnvelopeOpenText,
  faPaperPlane
} from "@fortawesome/free-solid-svg-icons"

const ContactForm = styled.form`
  display: grid;
  width: 100%;
  @media (min-width: 769px) {
    width: 50%;
  }
  grid-template-columns: 100%;
  grid-template-rows: repeat(5, auto);
  grid-row-gap: 20px;
  grid-column-start: 2;
  justify-items: center;
  justify-self: center;
  margin-top: 100px;
`
const ContactFormItem = styled.label`
  display: grid;
  grid-template-columns: 10% 90%;
  @media (min-width: 769px) {
    grid-template-columns: 7% 93%;
  }
  color: ${highlight};
  width: 100%;
`
const ContactFormInput = styled.input`
  border: 2px solid ${highlight};
  outline-width: 0;
  color: ${dark};
  padding-left: 10px;
  background-color: #e6e8fa;
  box-shadow: inset 6px 6px 10px 0 rgba(0, 0, 0, 0.2),
      inset -8px -8px 12px 0 rgba(255, 255, 255, 0.1);
    }
  @media(prefers-color-scheme: dark) {
    color: ${light};
    background-color: ${dark};
  }
  border-radius: 20px;
  ::placeholder {
    color: ${highlight};
  }
`
const ContactFormMessage = styled.textarea`
  border: 2px solid ${highlight};
  outline-width: 0;
  height: 100px;
  max-width: 100%;
  padding-left: 10px;
  border-radius: 20px;
  background-color: #e6e8fa;
  box-shadow: inset 6px 6px 10px 0 rgba(0, 0, 0, 0.2),
      inset -8px -8px 12px 0 rgba(255, 255, 255, 0.1);
    }
  ::placeholder {
    color: ${highlight};
  }
  color: ${dark};
 
  @media(prefers-color-scheme: dark) {
    color: ${light};
    background-color: ${dark};
  }
`
const FormIcon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  align-self: center;
`
const FormButton = styled(StyledButton)`
  width: 40%;
  outline:none;
`

const encode = (data:any) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const contactPage: React.FC = () => {
  const [validName, setValidName] = useState<boolean | undefined>(undefined)
  const [validEmail, setValidEmail] = useState<boolean | undefined>(undefined)
  const [validSubject, setValidSubject] = useState<boolean | undefined>(undefined)
  const [validMessage, setValidMessage] = useState<boolean | undefined>(undefined)
  const [formData, setFormData] = React.useState({})

  const handleChange = (e: any) => {
    e.target.name === "message" && setValidMessage(e.target.value.length > 0)
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...formData,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }


  return <>
    <Seo title="contact"/>
    <MainContainer>
      <ContactForm
        name="contact-form"
        id="contact-form"
        method="POST"
        action="/thanks/"
        data-netlify="true"
        netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="contact-form" />
        <div hidden>
          <label>
            Don’t fill this out: <input name="bot-field" onChange={handleChange} />
          </label>
        </div>
        <ContactFormItem aria-label="your name">
          <FormIcon icon={faUser}/>
          <ContactFormInput autofocus="true" required onChange={handleChange} onBlur={(e) => setValidName(e.target.checkValidity())} valid={validName} type="text" name="name" placeholder="Name"/>
        </ContactFormItem>
        <ContactFormItem aria-label="your email">
          <FormIcon icon={faAt}/>
          <ContactFormInput required onChange={handleChange} onBlur={(e) => setValidEmail(e.target.checkValidity())} type="email" name="email" placeholder="Email"/>
        </ContactFormItem>
        <ContactFormItem aria-label="subject">
          <FormIcon icon={faPenNib}/>
          <ContactFormInput required onChange={handleChange} onBlur={(e) => setValidSubject(e.target.checkValidity())} type="text" name="subject" placeholder="Subject"/>
        </ContactFormItem>
        <ContactFormItem aria-label="subject">
          <FormIcon icon={faEnvelopeOpenText}/>
        <ContactFormMessage required onChange={handleChange} name="message" placeholder="message">
        </ContactFormMessage>
        </ContactFormItem>
        <FormButton type="submit" aria-label="submit" enabled={validName && validEmail && validSubject && validMessage} disabled={!validName || !validEmail || !validSubject}>
          <FormIcon icon={faPaperPlane}/>
        </FormButton>
      </ContactForm>
    </MainContainer>
  </>

}
export default contactPage
